<template>
  <div>
    <v-carousel
			:height="size > 600 ? 'calc(50vh - 64px)' : 'calc(50vh - 56px)'"
			hide-delimiter-background
			cycle
		>
			<v-carousel-item v-for="(item, i) in carrusel" :key="i" :src="item"></v-carousel-item>
		</v-carousel>
    <v-sheet color="second" dark class="py-6 img" :class="size > 600 ?'px-12':'px-3'">
			<v-row no-gutters>
				<v-col cols="12" class="d-block mb-3">
					<h3 class="text-h4 text-center mt-4">
						BIENVENIDOS A
						<span class="text-uppercase">{{ marca }}</span>
					</h3>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="12" lg="5" v-if="$store.state.id_tw">
					<div class="py-12">
						<a
							class="twitter-timeline"
							data-height="500"
							data-dnt="true"
							data-theme="light"
							:href="$store.state.id_tw"
						>Tweets by track_races</a>
					</div>
				</v-col>
				<v-col v-if="$store.state.id_tw"></v-col>
				<v-col cols="12" md="12" lg="5">
					<form class="py-12" @submit.prevent="send">
						<div class="text-center">
							<span class="text-button">contactanos</span>
							<p class="text-caption">
								Estamos para ayudarle a resolver cualquier duda que tengas.
								<br />Por favor llene los siguentes campos.
							</p>
						</div>
						<v-row>
							<v-col>
								<v-text-field
									solo
									required
									light
									dense
									background-color="#eceff1"
									flat
									hide-details
									type="email"
									class="mr-auto"
									append-icon="mdi-email"
									label="Tu correo"
									v-model="correo"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field
									solo
									required
									light
									dense
									background-color="#eceff1"
									flat
									hide-details
									type="text"
									class="mr-auto"
									append-icon="mdi-text"
									label="Asunto del mensaje"
									v-model="asunto"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea
									solo
									v-model="msj"
									required
									light
									dense
									background-color="#eceff1"
									flat
									hide-details
									type="email"
									class="mr-auto"
									label="Escribe el mensaje"
								></v-textarea>
							</v-col>
						</v-row>
						<v-btn :loading="loading" block depressed class="mt-3" color="#fff" light type="submit">Enviar</v-btn>
						<p class="text-center mt-6 mb-0 text-overline">Le responderemos tan pronto como sea prosible.</p>
						<!-- <h6 class="text-center subtitle-1">Estamos para ayudarle a resolver cualquier duda que tengas.</h6> -->
						<!-- <h6 class="text-center caption">Contactanos al siguiente correo electronico</h6> -->
						<div class="d-flex flex-column align-center">
							
							<div >
								<v-icon size="30">mdi-email-outline</v-icon>
								<h7 class="ml-2 body-1 font-weight-medium">{{ email }}</h7>
							</div>
								<div>
								<v-btn @click="open($store.state.social.youtube)">
								<v-icon size="32">mdi-whatsapp</v-icon>
								<h5 class="ml-2 body-1 font-weight-medium">{{ $store.state.social.telefono}}</h5>
								</v-btn>
								</div>
							
							
						</div>
					</form>
				</v-col>
			</v-row>
		</v-sheet>
  </div>
  
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Tarjeta 1", image: "https://via.placeholder.com/150" },
        { title: "Tarjeta 2", image: "https://via.placeholder.com/150" },
        
        // ...
      ],
    };
  },
};
</script>

<style scoped>
.horizontal-list {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-list .v-col {
  display: inline-block;
}
</style>