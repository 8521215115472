<template>
  <div>
    <div class="container-bar">
      <v-dialog v-model="dialogMC" max-width="500" scrollable>
        <template v-slot:activator="{ on }">
          <input type="checkbox" v-if="$route.path == '/sport'" checked id="btn-social" />
          <input type="checkbox" v-else id="btn-social" />
          <label for="btn-social" class="fa fa-play"></label>
          <div class="icon-social">
            <v-btn small color="#D80A1C" v-on="on">
              <span class="white--text text-body-2 ">Info Bancaria </span
              ><v-icon class="white--text text-body "> mdi-bank</v-icon>
            </v-btn>
            <div><v-btn @click="
                dialogMC = false;
                $store.state.dialogRe = true;
                drawer = false;
                $router.push('/profile');
              " small color="#66bb6a">
              <span class="white--text  text-body-2">Agrega Saldo </span
              ><v-icon class="white--text text-body "> mdi-plus-circle</v-icon>
            </v-btn></div>
             <!-- <div v-show="size < 960" class="white"><v-img
              v-if="
                $route.path != '/concurso' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              @click.stop="$router.push('/concurso')"
              src="https://apitr.xyz/dominio/tr/saudi_cup.png"
              max-width="180"
              contain
            ></v-img></div>  -->
          </div>
          
        </template>

        <v-card>
          <v-card-title class="color body-1 px-5 py-3">
            Datos Bancarios
          </v-card-title>

          <v-card-text>
            <template>
              <div>
                <v-tabs v-model="active" color="blue" slider-color="red">
                  <v-tab>
                    USD
                  </v-tab>

                  <v-tab-item>
                    <v-card flat>
                      
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >Zelle</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            trabonoonline@gmail.com
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/zelle.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >AirTm</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            AbonoonlineTR@gmail.com
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/airtm.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <!-- <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >yappy</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Cuenta:507-63948758
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/yappy.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item> 

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >Banco General (Panama)</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: Carlos Andres Perez Leal
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 0472 9848 62886
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>-->

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >Binance Pay (USDT)</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            clecov@gmail.com
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://trackandraces.com/img/binancepay.webp"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                  </v-tab-item>

                  <v-tab>
                    VES
                  </v-tab>

                  <v-tab-item>
                    <v-card flat>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >Banco De Venezuela</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: Servicios Administrativos Integrales CG & CC
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 0102 0645 6500 0044 3023
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Rif: J500728999
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >BANESCO</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: Carlos Gonzalez
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 0134 1010 1100 0300 0907
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cedula: 15367718
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >Pago Movil BDV(Comercial)</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Banco: Banco de Venezuela
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Tlf: 04140898443
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Rif: J14291660
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                  </v-tab-item>

                  <v-tab>
                    COP
                  </v-tab>

                  <v-tab-item>
                    <v-card flat>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >NEQUI</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Cuenta:57-3015420990
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://assets-global.website-files.com/6317a229ebf7723658463b4b/631b991aff307a018c353dcd_Logo-Nequi.svg"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >DAVIPlata</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Cuenta:57-3023657173
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/daviplata3.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >BANCO DE BOGOTA (AHORRO)</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: Rhonny Diaz
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 387156516
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cedula: 1127621393
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >BANCOLOMBIA (AHORRO)</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: Kimberly Marcano
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 48100004911
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cedula: 5001241572
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                  </v-tab-item>

                  <v-tab>
                    PEN
                  </v-tab>

                  <v-tab-item>
                    <v-card flat>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >Yape</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Cuenta:51-917073520
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/yape.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >Plin</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Cuenta:51-933292975
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/plim.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >BCP</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: SYELTECH S.A.C
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 194-8967899-0-35
                          </v-list-item-subtitle>
                          <v-list-item-subtitle> </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >BBVA</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: SYELTECH S.A.C
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 0011-0137-0100052348
                          </v-list-item-subtitle>
                          <v-list-item-subtitle> </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >INTERBANK</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: SYELTECH S.A.C
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 3683003133099
                          </v-list-item-subtitle>
                          <v-list-item-subtitle> </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-1"
                            style="font-weight:500"
                            >Scotiabank</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Nombre: MAYULIZ PEREZ
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Cuenta: 074-7481992
                          </v-list-item-subtitle>
                          <v-list-item-subtitle> </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar color="white">
                          <v-img
                            class
                            contain
                            src="https://apitr.xyz/cuentas/banktranfer.png"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </div>
            </template>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn color="#fff" flat @click="dialogMC = false">
              Cerrar
            </v-btn>
            
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
export default {
  data() {
    return {
      dialogm1: 0,
      dialog: true,
      dialogMC: false,
      items: [
        {
          avatar: "https://apitr.xyz/cuentas/paypal.png",
          title: "Paypal",
          subtitle:
            "<span class='body-1'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle:
            "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.",
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Oui oui",
          subtitle:
            "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?",
        },
      ],
    };
  },
  methods: {},
  computed: {
    size() {
      return this.$store.state.windowSize.x;
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-bar {
  width: 80%;
  max-width: 158px;
  position: fixed;
  left: 0;
  top: 74%;
}

.fa-play {
  cursor: pointer;
  transition: all 0.4s;
  font-size: 12px;
  margin-bottom: 5px;
  display: inline-block;
  transform: rotate(180deg);
  color: #49b0e6;
}
#btn-social:checked ~ .fa-play {
  transform: rotate(0deg);
}
.icon-social {
  transition: all 0.4s;
  transform: translateX(-7%);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
}
#btn-social:checked ~ .icon-social {
  transform: translateX(-70%);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0);
}

.v-tab {
  left: 0;
  margin: 0px -14px;
}
</style>
