<template>
  <div v-if="this.$store.state.pais_user != 'Venezuela'">
    <div v-if="this.$store.state.idpromodia > 0">
      <v-btn color="pink" label text-color="white"
           small
           
            
            
            ><v-icon class="white--text  ">mdi-label</v-icon>
    <span class="white--text  ">Promo: {{ this.$store.state.nbpromodia }}</span>
    </v-btn>
      
    </div>
    <div v-if="this.$store.state.idpromodia === undefined">
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="330px"
          no-click-animation
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ dialogm1 }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Selecciona tu Promo de Hoy</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 200px;">
              <v-radio-group v-model="dialogm1" column>
                <v-radio value="Escala de Dividendos"
                  ><template v-slot:label>
                    <div>
                      <img src="https://trackandraces.com/dominio/0501.png" />
                    </div> </template
                ></v-radio>
                <v-radio value="30% Primer Deposito"
                  ><template v-slot:label>
                    <div>
                      <img src="https://trackandraces.com/dominio/30p.png" />
                    </div> </template
                ></v-radio>
                
              </v-radio-group>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="dialogPreg = true">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPreg" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              Confirma su eleccion?
            </v-card-title>
            <v-card-text
              >Esta promo estara en funcionamiento por el dia de
              Hoy</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialogPreg = false">
                Cancelar
              </v-btn>
              <v-btn color="green darken-1" text @click="guardarSeleccion()">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialogm1: 0,
      dialog: true,
      dialogPreg: false,
    };
  },
  methods: {
    guardarSeleccion() {
      if (this.dialogm1 == 0) {
        alert("Selecciones una promo para su activacion");
        this.dialogPreg = false;
      } else {
        axios({
          method: "post",
          url: `/guardarpromodia`,
          data: {
            promo: this.dialogm1,
          },
        }).then((response) => {
          this.$store.state.msg = response.data.mensaje;
          this.$store.state.idpromodia = 1;
          this.$store.state.nbpromodia = this.dialogm1;
          this.dialogPreg = false;
          this.dialog = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
